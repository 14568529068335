import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { ApiResponseMessage, ApiResponse } from "../../dtos/response.dto";
import { ProgramInfoData, ProgramStatus, ProgramViewEditResponse } from "../../dtos/program-info.dto";
import { SystemConfigurationResponse } from "../../dtos/system-configuration-response.dto";
import { ApplicationArea } from "../../dtos/application-area.dto";
import { Buyer } from "../../dtos/buyer.dto";
import { CompaniesDetailsData, CompanyDetailsData, CompanyMasterInfoData } from "../../dtos/companies-details.dto";
import { CoacheeEmailData, CoacheeListData } from "../../dtos/coachee-list.dto";
import { TopicListData } from "../../dtos/topic.dto";
import { UseCase } from "../../dtos/usecase.dto";
import { CoachData, CoachListData } from "../../dtos/coach.dto";
import { ReschedulingRequestSessionsResponse, SessionsResponse, UnassignedCoacheeSessionsResponse } from "../../dtos/session.dto";
import { ResetPasswordResponse } from "../../dtos/reset-password.dto";
import { User } from "../../dtos/user.dto";
import { SuperAdminInfo } from "../../dtos/admin-info.dto";
import { AdminEntity } from "../../dtos/admin-entity";

export type LoginResponse = {
  challenge: string
}

@Injectable({ providedIn: "root" })
export class SuperAdminService {
  constructor(private _httpService: HttpService, private _router: Router) { }

  //login service
  initiateLogin(login_info): Observable<LoginResponse> {
    return this._httpService.post<LoginResponse>('/api/v2/admin/login', login_info);
  }

  submitOtp(otpVerificationForm): Observable<void> {
    return this._httpService.post<void>('/api/v2/admin/mfa', otpVerificationForm);
  }

  // sendPasswordToEmail
  sendPasswordToEmail(email_info): Observable<unknown> {
    return this._httpService.post<unknown>('/api/v1/super_admin_forgot_password', email_info);
  }

  //verify reset password token 404
  verifyPasswordResetToken(token_info): Observable<ResetPasswordResponse> {
    return this._httpService.post<ResetPasswordResponse>('/api/v1/super_admin_decrypt_reset_password_data', token_info);
  }

  //reset the password 404
  resetPassword(password_details): Observable<unknown> {
    return this._httpService.post<unknown>('/api/v1/super_admin_reset_password', password_details);
  }

  getSystemSessionConfiguration(): Observable<SystemConfigurationResponse> {
    return this._httpService.get<SystemConfigurationResponse>(`/api/v1/admin/system-configuration`)
      .pipe(this.redirectOnUnauthorized(), map(response => response as SystemConfigurationResponse));
  }

  //get application area list
  getApplicationAreaList(): Observable<ApiResponse<ApplicationArea>> {
    return this._httpService.get<ApiResponse<ApplicationArea>>('/api/v1/get_application_area_list')
      .pipe(this.redirectOnUnauthorized(), map((response: ApiResponse<ApplicationArea>) => response));
  }


  //get buyer list
  getBuyerList(): Observable<ApiResponse<Buyer>> {
    return this._httpService.get<ApiResponse<Buyer>>('/api/v1/get_buyer_list')
      .pipe(this.redirectOnUnauthorized(), map((response: ApiResponse<Buyer>) => response)
      );
  }

  //get company master info
  getCompanyMasterInfo(): Observable<ApiResponse<CompanyMasterInfoData>> {
    return this._httpService.get<ApiResponse<CompanyMasterInfoData>>('/api/v1/get_new_company_master_info')
      .pipe(this.redirectOnUnauthorized(), map((response: ApiResponse<CompanyMasterInfoData>) => response));
  }

  //get company details
  getCompaniesDetails(company_info): Observable<ApiResponse<CompaniesDetailsData>> {
    return this._httpService.post<ApiResponse<CompaniesDetailsData>>('/api/v1/get_company_list', company_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<CompaniesDetailsData>));
  }

  //get company details by passing comapany_id
  getCompanyDetails(id): Observable<ApiResponse<CompanyDetailsData>> {
    return this._httpService.post<ApiResponse<CompanyDetailsData>>('/api/v1/view_edit_company', id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<CompanyDetailsData>));
  }

  //get coachee list
  getAllCoachees(program_id): Observable<ApiResponse<CoacheeListData>> {
    return this._httpService.post<ApiResponse<CoacheeListData>>('/api/v1/view_edit_selected_coachee_program', program_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<CoacheeListData>));
  }

  //get Topic details
  getTopics(topics_info): Observable<ApiResponse<TopicListData>> {
    return this._httpService.post<ApiResponse<TopicListData>>('/api/v1/get_topic_list', topics_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<TopicListData>));
  }

  //add company details
  createCompanyDetails(company_data): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/create_company', company_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //edit company details
  editCompanyDetails(company_info): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/edit_company_details', company_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //delete Topic
  deleteCompany(company_id): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/delete_company', company_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //programs
  getAllPrograms(paginator_info): Observable<ApiResponse<ProgramInfoData>> {
    return this._httpService.post<ApiResponse<ProgramInfoData>>('/api/v1/get_program_list', paginator_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ProgramInfoData>));
  }

  //add program details
  createProgram(program_data): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/create_program', program_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //get program details by passing program id
  fetchProgramDetails(id): Observable<ApiResponse<ProgramViewEditResponse>> {
    return this._httpService.post<ApiResponse<ProgramViewEditResponse>>('/api/v1/view_edit_program', id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ProgramViewEditResponse>));
  }

  //edit program details
  editProgramInfo(program_info): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/edit_program_details', program_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //delete program
  deleteProgram(program_info): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/delete_program', program_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //get program status list
  getProgramStatusList(): Observable<ApiResponse<ProgramStatus>> {
    return this._httpService.get<ApiResponse<ProgramStatus>>('/api/v1/get_program_status_list')
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ProgramStatus>));
  }

  //get Topic details by passing comapany_id
  fetchTopicDetails(id): Observable<ApiResponse<TopicListData>> {
    return this._httpService.post<ApiResponse<TopicListData>>('/api/v1/view_edit_topic', id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<TopicListData>));
  }

  //edit Topic details
  editTopic(id): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/edit_topic_details', id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //add Topic
  createTopic(topic_details): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/create_topic', topic_details)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //delete Topic
  deleteTopic(topic_id): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/delete_topic', topic_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //get Usecase details
  getUsecases(topicId): Observable<ApiResponse<UseCase[]>> {
    return this._httpService.get<ApiResponse<UseCase[]>>(`/api/v1/admin/topics/${topicId}/use-cases`)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<UseCase[]>))
  }

  createUsecase(topicId: number, usecaseDetails: any): Observable<UseCase> {
    return this._httpService.post<UseCase>(`/api/v1/admin/topics/${topicId}/use-cases`, usecaseDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as UseCase))
  }

  //get Usecase details by passing comapany_id
  fetchUsecaseDetails(topicId: string, usecaseId: string): Observable<ApiResponse<UseCase>> {
    return this._httpService.get<ApiResponse<UseCase>>(`/api/v1/admin/topics/${topicId}/use-cases/${usecaseId}`)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<UseCase>))
  }

  editUsecase(topicId: number, usecaseId: number, usecaseData: any): Observable<UseCase> {
    return this._httpService.put<UseCase>(`/api/v1/admin/topics/${topicId}/use-cases/${usecaseId}`, usecaseData)
      .pipe(this.redirectOnUnauthorized(), map(response => response as UseCase))
  }

  deleteUsecase(topicId: number, usecaseId: number, usecaseData: any): Observable<void> {
    return this._httpService.delete<void>(`/api/v1/admin/topics/${topicId}/use-cases/${usecaseId}`, usecaseData)
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  deleteUseCaseImage(topicId: number, usecaseId: number, image: any): Observable<void> {
    return this._httpService.delete<void>(`/api/v1/admin/topics/${topicId}/use-cases/${usecaseId}/image`, image)
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  setUseCaseImage(topicId: number, usecaseId: number, image: any): Observable<void> {
    return this._httpService.post<void>(`/api/v1/admin/topics/${topicId}/use-cases/${usecaseId}/image`, image)
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  //get all coaches list
  getAllCoaches(pagination_data): Observable<ApiResponse<CoachListData>> {
    return this._httpService.post<ApiResponse<CoachListData>>('/api/v1/get_coach_list', pagination_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<CoachListData>));
  }

  //add coaches
  createCoach(coach_details): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/create_coach', coach_details)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>));
  }

  //update coach
  fectchCoach(coach_id): Observable<ApiResponse<CoachData>> {
    return this._httpService.post<ApiResponse<CoachData>>('/api/v1/super_admin_view_edit_coach', coach_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<CoachData>));
  }

  //update coach
  updateCoach(coach_details): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<CoachListData>>('/api/v1/super_admin_edit_coach_details', coach_details)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  //delete Coach
  deleteCoach(coach_id): Observable<void> {
    return this._httpService.post<void>('/api/v1/delete_coach', coach_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  deleteCoachee(coachee_id): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/delete_coachee', coachee_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  //fetch details of coachee using email
  coacheEmailtoFetchDetails(program_info): Observable<ApiResponse<CoacheeEmailData>> {
    return this._httpService.post<ApiResponse<CoacheeEmailData>>('/api/v1/post_fetch_coachee_program_info', program_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<CoacheeEmailData>))
  }

  //upload excel sheet
  uploadExcelSheet(excel_document): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/post_import_program_coachees_data', excel_document)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  //post coachee details
  postCoacheeDetails(coachee_info): Observable<unknown> {
    return this._httpService.post<unknown>('/api/v1/super_admin_add_or_edit_coachee_details', coachee_info)
      .pipe(this.redirectOnUnauthorized())
  }

  // get session list
  getSessionList(paginator_info): Observable<ApiResponse<UnassignedCoacheeSessionsResponse>> {
    return this._httpService.post<ApiResponse<UnassignedCoacheeSessionsResponse>>('/api/v1/get_unassigned_coachee_sessions_list', paginator_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<UnassignedCoacheeSessionsResponse>))
  }

  // get session list
  getAllCoacheeSessionList(paginator_info): Observable<ApiResponse<SessionsResponse>> {
    return this._httpService.post<ApiResponse<SessionsResponse>>('/api/v1/get_all_coachee_sessions_list', paginator_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<SessionsResponse>))
  }

  //get sessions which are not confirmed
  getSessionsNotConfirmed(paginator_info): Observable<ApiResponse<SessionsResponse>> {
    return this._httpService.post<ApiResponse<SessionsResponse>>('/api/v1/get_session_not_confirmed_list', paginator_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<SessionsResponse>))
  }

  //get rescheduled session list
  getRescheduledSessionList(paginator_info): Observable<ApiResponse<ReschedulingRequestSessionsResponse>> {
    return this._httpService.post<ApiResponse<ReschedulingRequestSessionsResponse>>('/api/v1/get_rescheduling_request_sessions_list', paginator_info)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ReschedulingRequestSessionsResponse>))
  }

  //post session confirm data !!!
  postSessionConfirmData(session_data): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/post_assign_coach_and_time_to_coachee_session', session_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  // !!! not found
  getAllVideoCallLinks(paginator_info): Observable<unknown> {
    return this._httpService.post<unknown>('/api/v1/get_ms_team_list', paginator_info)
      .pipe(this.redirectOnUnauthorized())
  }

  //post session override time
  postSessionRescheduledTime(session_data): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/post_assign_session_time_to_rescheduling_request_sessions', session_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  uploadVideoCallLinksExcelSheet(excel_document): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/post_import_ms_team_import_data', excel_document)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  ///
  endCoachingJourney(journeyId): Observable<void> {
    return this._httpService.post<void>(`/api/v2/admin/journeys/${journeyId}/end`, '')
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  postUpcomingSessionCancel(session_data): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/post_cancel_session_request', session_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  updateTimeSlots(id, coacheeTimePreferences): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.put<ApiResponse<ApiResponseMessage>>(`/api/v2/admin/sessions/${id}/time-preferences`, coacheeTimePreferences)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  updateSessionTime(id, newSessionTime): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.put<ApiResponse<ApiResponseMessage>>(`/api/v1/admin/sessions/${id}/time`, newSessionTime)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  postUpdateReschedulingReason(session_data): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/post_update_cancel_session_request_reason', session_data)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  //edit super admin profile details
  editAdminProfileDetails(admin_profile_details): Observable<ApiResponse<ApiResponseMessage>> {
    return this._httpService.post<ApiResponse<ApiResponseMessage>>('/api/v1/edit_super_admin_profile_details', admin_profile_details)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<ApiResponseMessage>))
  }

  //get super admin profile details
  getAdminProfileDetails(user_id): Observable<ApiResponse<SuperAdminInfo>>  {
    return this._httpService.post<ApiResponse<SuperAdminInfo>>('/api/v1/view_edit_super_admin_profile_details', user_id)
      .pipe(this.redirectOnUnauthorized(), map(response => response as ApiResponse<SuperAdminInfo>))
  }

  //get excel sheet
  getExcelSheet(program_info) {
    return this._httpService.postWithBlobResponse<any>('/api/v1/get_excel_sheet_for_program', program_info)
      .pipe(this.redirectOnUnauthorized())
  }

  getVideoCallLinkExcelSheet(program_info) {
    return this._httpService.postWithBlobResponse<any>('/api/v1/get_excel_sheet_for_ms_team_links', program_info)
      .pipe(this.redirectOnUnauthorized())
  }

  getAllProgramOwners() : Observable<AdminEntity[]> {
    return this._httpService.get<AdminEntity[]>(`/api/v2/admin/owners`)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity[]))
  }

  createProgramOwner(programOwnerDetails): Observable<AdminEntity> {
    return this._httpService.post<AdminEntity>(`/api/v2/admin/owners`, programOwnerDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity))
  }

  editProgramOwner(id, programOwnerDetails): Observable<AdminEntity> {
    return this._httpService.put<AdminEntity>(`/api/v2/admin/owners/${id}`, programOwnerDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity))
  }

  deleteProgramOwner(id): Observable<void> {
    return this._httpService.delete<void>(`/api/v2/admin/owners/${id}`, '')
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  getAllSponsors() : Observable<AdminEntity[]> {
    return this._httpService.get<AdminEntity[]>(`/api/v2/admin/sponsors`)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity[]))
  }

  createSponsor(sponsorDetails): Observable<AdminEntity> {
    return this._httpService.post<AdminEntity>(`/api/v2/admin/sponsors`, sponsorDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity))
  }

  editSponsor(id, sponsorDetails): Observable<AdminEntity> {
    return this._httpService.put<AdminEntity>(`/api/v2/admin/sponsors/${id}`, sponsorDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity))
  }

  deleteSponsor(id): Observable<void> {
    return this._httpService.delete<void>(`/api/v2/admin/sponsors/${id}`, '')
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  getAllSparrksOps() : Observable<AdminEntity[]> {
    return this._httpService.get<AdminEntity[]>(`/api/v2/admin/ops`)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity[]))
  }

  createSparrksOp(sponsorDetails): Observable<AdminEntity> {
    return this._httpService.post<AdminEntity>(`/api/v2/admin/ops`, sponsorDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity))
  }

  editSparrksOp(id, sponsorDetails): Observable<AdminEntity> {
    return this._httpService.put<AdminEntity>(`/api/v2/admin/ops/${id}`, sponsorDetails)
      .pipe(this.redirectOnUnauthorized(), map(response => response as AdminEntity))
  }

  deleteSparrksOp(id): Observable<void> {
    return this._httpService.delete<void>(`/api/v2/admin/ops/${id}`, '')
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  updateCoachAnalytics(id, url): Observable<any> {
    return this._httpService.put<any>(`/api/v2/admin/coaches/${id}/analytics`, url)
      .pipe(this.redirectOnUnauthorized(), map(response => response as any))
  }

  deleteCoachAnalytics(id): Observable<void> {
    return this._httpService.delete<void>(`/api/v2/admin/coaches/${id}/analytics`, '')
      .pipe(this.redirectOnUnauthorized(), map(response => response as void))
  }

  changeCoach(journeyId): Observable<void>  {
    return this._httpService.post<any>(`/api/v1/admin/journeys/${journeyId}/change-coach`, '')
    .pipe(this.redirectOnUnauthorized(), map(response => response as any))
  }

  private redirectOnUnauthorized() {
    const router = this._router
    return catchError(err => {
      if (err.status === 401) {
        router.navigate(['account/admin-login']);
      }
      throw err
    })
  }
}
